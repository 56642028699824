var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-8 text-xs"},[_c('button',{staticClass:"flex items-center text-blue-500",on:{"click":function($event){return _vm.$router.push('/publictoken')}}},[_c('i',{staticClass:"fas fa-chevron-left w-6 h-6 mb-2"}),_c('span',{staticClass:"ml-2 mb-5"},[_vm._v("Kembali")])]),_c('div',{staticClass:"rounded-2xl bg-white p-8"},[_c('div',{},[_vm._m(0),_c('div',{staticClass:"mt-4 grid grid-cols-12 gap-4"},[_c('div',{staticClass:"col-span-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.postData()}}},[_c('div',{staticClass:"grid grid-cols-1 gap-4 "},[_c('ValidationProvider',{staticClass:"grid grid-cols-12 gap-4 items-center",attrs:{"rules":"required","name":"token","vid":"token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-1",attrs:{"for":""}},[_vm._v("Token")]),_c('div',{staticClass:"col-span-11"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.token),expression:"addData.token"}],ref:"token",class:("block border border-gray-200 w-full p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"text","name":"token","id":"token"},domProps:{"value":(_vm.addData.token)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addData, "token", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"grid grid-cols-1 gap-4 mt-4"},[_c('ValidationProvider',{staticClass:"grid grid-cols-12 gap-4 items-center",attrs:{"rules":"required","name":"expired","vid":"expired"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-1",attrs:{"for":""}},[_vm._v("Expired")]),_c('div',{staticClass:"col-span-11"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.expired),expression:"addData.expired"}],ref:"expired",class:("block border border-gray-200 w-full p-2 px-4 rounded  text-xs " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"type":"date","name":"expired","id":"expired"},domProps:{"value":(_vm.addData.expired)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.addData, "expired", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"grid grid-cols-1 gap-4 mt-4"},[_c('ValidationProvider',{staticClass:"grid grid-cols-12 gap-4 items-center",attrs:{"name":"Catatan","vid":"note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 col-span-1",attrs:{"for":""}},[_vm._v("Perangkat")]),_c('div',{staticClass:"col-span-11"},[_c('v-select',{attrs:{"options":_vm.device,"multiple":"","reduce":function (device) { return device.id; },"label":"name"},model:{value:(_vm.addData.device_id),callback:function ($$v) {_vm.$set(_vm.addData, "device_id", $$v)},expression:"addData.device_id"}}),_c('span',{staticClass:"text-red-600 text-xs col-span-10"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"float-right"},[_c('button',{class:"text-center py-2 px-8 rounded\n                            \n                            border border-gray\n                            mr-2\n                            hover:bg-gray-100\n                            disabled:opacity-50\n                            mt-4\n                            \n                            focus:outline-none my-1",on:{"click":function($event){return _vm.$router.push('/publictoken')}}},[_vm._v("Batal")]),_c('button',{class:"text-center py-2 px-8 rounded\n                            text-white\n                            hover:bg-blue-900 bg-blue-500\n                            disabled:opacity-50\n                            mt-4\n                            \n                            focus:outline-none my-1",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v("Simpan")])])])]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('span',{staticClass:"font-semibold text-base"},[_vm._v("Tambah Public Token")])])}]

export { render, staticRenderFns }